@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
html {
  scroll-behavior: smooth;
}
h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
  font-weight: bold;
}
h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.8em;
}
h3 {
  font-size: 1.6em;
}
h4 {
  font-size: 1.4em;
}
h5 {
  font-size: 1.2em;
}
h6 {
  font-size: 1em;
}
ol, ul {
  margin-bottom: 0;
  padding-left: 0;
}
table {
  width: 100%;
  margin-bottom: 1.5em;
  border-spacing: 0;
  border-collapse: collapse;
}
table th, table td {
  padding: 0.5em;
  border: 1px solid #000;
}
table th {
  font-weight: bold;
}
caption, table th, table td {
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
}
q, blockquote {
  quotes: none;
}
q::before, q::after, blockquote::before, blockquote::after {
  content: none;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}
*, *::before, *::after {
  box-sizing: border-box;
}
body * {
  max-height: 99999em;
  word-break: break-word;
}
body {
  background-color: #000;
  font-family: "Radio Canada Big", sans-serif;
  font-optical-sizing: auto;
  font-style: 400;
  color: #fff;
  padding-top: 93px;
}
button {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 1024px) {
  body {
    padding-top: 85px;
  }
  body.open-menu {
    position: relative;
    overflow: hidden;
  }
  body.open-menu:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.8);
    z-index: 101;
  }
}
p {
  margin-bottom: 0;
}
a {
  color: inherit;
}
a:hover {
  text-decoration: none;
}
img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
a img {
  border: none;
}
input, label, select, button {
  line-height: inherit;
}
input, label, select, button, textarea {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  font: inherit;
  word-break: normal;
  vertical-align: middle;
  white-space: normal;
  -webkit-appearance: none;
}
button {
  color: inherit;
}
textarea {
  overflow: auto;
  line-height: 1.5;
  white-space: pre-line;
  resize: none;
}
button:focus {
  outline: 0;
}
input:focus, textarea:focus, select:focus {
  outline: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
}
::-webkit-search-decoration {
  display: none;
}
